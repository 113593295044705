@import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css);
/*@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);*/


@font-face {
    font-family: "Helvetica Neue";
    font-style: normal;
    /*font-weight: 400;*/
    /*src: local('Righteous'), local('Righteous-Regular'), url(https://fonts.gstatic.com/s/righteous/v8/1cXxaUPXBpj2rGoU7C9WiHGFq8Kk1Q.woff2) format('woff2');*/
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.tool_panel{
    margin-left: 20px;
    padding-bottom: 100px;
    width: 48%;
}

.banner {
    position: fixed;
    bottom: 0;
    left: 100%;
    transform: translateX(-50%);
    width: 100px;
    height: 50px;
    border: 0;
    z-index: 1000; /* Ensure the iframe is above other content */
}

.tool_control_panel{
    /*background-color: #8a8a8a;*/
    margin-top:10px;
    padding-top: 10px;
    padding-left: 25px;
    display: flex;
    flex-direction: row;
    width: 48%;
    overflow: scroll;
    flex-wrap: wrap;
    max-height: 99%;
    position: fixed;
    top: 290px;
    bottom: 1px;
}


.button-tight {
    height:30px;margin: 10px 0px 0px 0px;
}

@media screen and (max-width : 500px) {
    .mobile_hide
    {
        display: none;
    }
    .tool_panel {
        width: 93%;
    }
    .tool_control_panel{
        width: 93%;
        top: 290px;
    }
}

@media screen and (min-width : 501px) and (max-width : 700px){
    .tool_control_panel{
        top: 350px;
    }

    .setting_page{
        line-height: 130%;
    }

}

@media screen and (min-width : 701px) and (max-width : 800px){
    .tool_control_panel{
        top: 335px;
    }
    .setting_page{
        line-height: 130%;
    }
}

@media screen and (min-width : 801px) and (max-width : 1010px){
    .tool_control_panel{
        top: 290px;
    }
}

@media screen and (min-width : 1080px){
    .tool_control_panel{
        top: 250px;

    }
}


.selectbox_dyn{
    width: 150px !important;
    margin: 0px;
    padding: 0px;
}


.fieldset {
    border: 1px solid #ccc;
    border-top: none;
    padding: 0.5em;
    margin: 0.5em 2px;

    /*display: inline-block;*/
    /*position: relative;*/
    /*min-width: 500px;*/
}

.fieldset>legend {
    font: 1em normal;
    margin: -1em -0.5em 0;
}

.fieldset>legend>span {
    float: left;
}

.fieldset>legend:before {
    border-top: 1px solid #ccc;
    content: ' ';
    float: left;
    margin: 0.5em 2px 0 -2px;
    width: 0.75em;
}

.fieldset>legend:after {
    border-top: 1px solid #ccc;
    content: ' ';
    display: block;
    height: 1.5em;
    left: 2px;
    margin: 0 -2px 0 0;
    overflow: hidden;
    position: relative;
    top: 0.5em;
}

.inline {
    display: inline;
    margin: 10px;
}

.input_default {
    border: 1px solid #ccc;
    margin: 0px;
    width: 96%;
    padding: 3px 3px 3px 3px;
    font-size: 11px;
}
.password {
    border: 1px solid #ccc;
    margin: 0px;
    padding: 3px 3px 3px 3px;
    width: 200px;
    color: #174a2c;
    text-security:disc;
    -webkit-text-security:disc;
    -mox-text-security:disc;

}
input::placeholder {
    color: #b8b8b8;
    font-style: italic;
}

.collapse-list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    border-bottom: 1px solid #e0e0e0;
}

.collapse-open {
    display: none;
}

.collapse-panel {
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height .1s,
    visibility .3s,
    opacity .3s;
}

.collapse-open:checked ~ .collapse-panel {
    max-height: 100%;
    opacity: 100;
    visibility: visible
}

.collapse-list li {
    margin-bottom: 0;
}

.collapse-list .collapse-btn {
    border-top: 1px solid #e0e0e0;
    cursor: pointer;
    display: block;
    padding: 15px 10px;
    margin-bottom: 0;
    color: #3c574c;
    font-weight: normal;
    transition: background-color .2s ease;
}

.collapse-list .collapse-btn:hover {
    background: #eee;
}

.collapse-open ~ .collapse-btn:before {
    content: "↓";
    float: right;
}

.collapse-open:checked ~ .collapse-btn:before {
    content: "↑";
}

.shadowBox {
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.flex-box {
    display:flex;
    justify-content:space-between;
    margin-top: 5px;
    margin-bottom: 5px;
}


.collapse-list .collapse-inner {
    padding: 10px
}

/*legend {*/
/*    font-weight: bold;*/
/*    color: #707070;*/
/*    margin: 5px 0px -5px*/
/*}*/

.gray_input {
    border: 1px solid #ccc;
    margin: 0px;
    padding: 2px;
    width: 99%;
}
.tight{
    margin: 0px;
    padding: 0px;
}
.h1, .leader, h1 {
    font-family: "Helvetica Neue";
    font-weight: 100;
    font-size: 3rem;
    margin: 10px 0px 10px 0px;
}
h1 small{
    font-size: 1.5rem;
}

h4 {
    font-weight: 100;
    font-size: 1.3rem;
    margin: 0px 0px 10px 0px;
}

.result_table {
    margin-top: 2px;
    /*font-family: 'Noto Sans KR', sans-serif;*/
    font-family: "Spoqa Han Sans";
    /*font-famliy: "monospace" !important;*/
    font-size: 12px;
    width: 100%;
    table-layout: auto;
    border: 1px solid #afafaf ;
    border-collapse: collapse;
    word-break: break-all;

}
.result_table td {
    padding: 5px;
    word-wrap:break-word; /*old browsers*/
    overflow-wrap:break-word;
    border: 1px solid #afafaf ;
    min-width: 150px;
    /*background-color: red;*/

}

table caption {
    padding: 10px;
    background: linear-gradient(90deg, #9B46DD 0%, #3D8DD6 98.44%);
    font-weight: bold;
    font-size: 1.1em;
    margin-left: -1px; /* Fix FF 1px issue */
}

.table_key {
    padding: 5px;
    background-color: #f8f8f8;

}
#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
#cssmenu:after,
#cssmenu > ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
#cssmenu {
    width: auto;
    border-bottom: 3px solid #9B46DD;
    font-family: Raleway, sans-serif;
    line-height: 1;
    margin-bottom: 15px;
}
#cssmenu ul {
    background: #ffffff;
}
#cssmenu > ul > li {
    float: left;
}
#cssmenu.align-center > ul {
    font-size: 0;
    text-align: center;
}
#cssmenu.align-center > ul > li {
    display: inline-block;
    float: none;
}
#cssmenu.align-right > ul > li {
    float: right;
}
#cssmenu.align-right > ul > li > a {
    margin-right: 0;
    margin-left: -4px;
}
#cssmenu > ul > li > a {
    z-index: 2;
    padding: 18px 18px 12px 18px;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    color: #444444;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    margin-right: -4px;
}
#cssmenu > ul > li:hover > a,
#cssmenu > ul > li > a:hover {
    color: white;
    filter: alpha(opacity=50);
    opacity: 0.5;

}

#cssmenu > ul > li.active > a{
    color: white;
}

#cssmenu > ul > li > a:after {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 120%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    content: "";
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-transform: perspective(5px) rotateX(2deg);
    -webkit-transform-origin: bottom;
    -moz-transform: perspective(5px) rotateX(2deg);
    -moz-transform-origin: bottom;
    transform: perspective(5px) rotateX(2deg);
    transform-origin: bottom;
}
#cssmenu > ul > li.active > a:after,
#cssmenu > ul > li:hover > a:after,
#cssmenu > ul > li > a:hover:after {
    background: linear-gradient(90deg, #9B46DD 0%, #3D8DD6 98.44%);
}
body {
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    font-family: "Helvetica Neue",monospace;
    /*min-width:500px !important;        !* Suppose you want minimum width of 1000px *!*/
    /*font-family: 'Noto Sans KR', sans-serif;*/
}

textarea {
    font-family: 'Noto Sans KR', sans-serif;
}

.ace_content {
    font-family: 'Noto Sans KR', sans-serif !important;
}

pre > code {
    /*font-family: 'Noto Sans KR', sans-serif;*/
    /*font-family: "Sans Mono", "Consolas", "Courier", monospace;*/
    font-family: "Spoqa Han Sans", 'Sans-serif';

}


#log-container { overflow: auto; height: 150px; }

.log-warn { color: orange }
.log-error { color: red }
.log-info { color: skyblue }
.log-log { color: silver }

.log-warn, .log-error { font-weight: bold; }

.logging-date{
   background:#f2f2f2;border: 1px solid #ededed;margin-right:3px;
}
.logging-data{

}
.logging{
    border: 1px solid rgb(237, 237, 237); padding: 8px;
}
.data-key {
    padding-right: 5px;
}
pre, code {
      white-space: pre;
      overflow-x: scroll;
}
.hljs{
    font-size: 11px;
    /*display: inline-block;*/
    /*white-space: pre;*/
    /*overflow-x: auto;*/
    /*padding: 0.5em;*/
    /*color: #333;*/
    /*background: #f8f8f8;*/
}

.cal_size{
    font-size:11px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: grey
}


/* dark styling of the editor */
div.jsoneditor,
div.jsoneditor-menu {
    border-color: #9B46DD;
}
div.jsoneditor-menu {
    background: linear-gradient(90deg, #9B46DD 0%, #3D8DD6 98.44%);
}

/*div.jsoneditor-tree,*/
/*div.jsoneditor textarea.jsoneditor-text {*/
/*    background-color: #666666;*/
/*    color: #ffffff;*/
/*}*/
/*div.jsoneditor-field,*/
/*div.jsoneditor-value {*/
/*    color: #ffffff;*/
/*}*/
/*table.jsoneditor-search div.jsoneditor-frame {*/
/*    background: #808080;*/
/*}*/

/*tr.jsoneditor-highlight,*/
/*tr.jsoneditor-selected {*/
/*    background-color: #808080;*/
/*}*/

/*div.jsoneditor-field[contenteditable=true]:focus,*/
/*div.jsoneditor-field[contenteditable=true]:hover,*/
/*div.jsoneditor-value[contenteditable=true]:focus,*/
/*div.jsoneditor-value[contenteditable=true]:hover,*/
/*div.jsoneditor-field.jsoneditor-highlight,*/
/*div.jsoneditor-value.jsoneditor-highlight {*/
/*    background-color: #808080;*/
/*    border-color: #808080;*/
/*}*/

/*div.jsoneditor-field.highlight-active,*/
/*div.jsoneditor-field.highlight-active:focus,*/
/*div.jsoneditor-field.highlight-active:hover,*/
/*div.jsoneditor-value.highlight-active,*/
/*div.jsoneditor-value.highlight-active:focus,*/
/*div.jsoneditor-value.highlight-active:hover {*/
/*    background-color: #b1b1b1;*/
/*    border-color: #b1b1b1;*/
/*}*/

/*div.jsoneditor-tree button:focus {*/
/*    background-color: #868686;*/
/*}*/

/*!* coloring of JSON in tree mode *!*/
/*div.jsoneditor-readonly {*/
/*    color: #acacac;*/
/*}*/
/*div.jsoneditor td.jsoneditor-separator {*/
/*    color: #acacac;*/
/*}*/
/*div.jsoneditor-value.jsoneditor-string {*/
/*    color: #00ff88;*/
/*}*/
/*div.jsoneditor-value.jsoneditor-object,*/
/*div.jsoneditor-value.jsoneditor-array {*/
/*    color: #bababa;*/
/*}*/
/*div.jsoneditor-value.jsoneditor-number {*/
/*    color: #ff4040;*/
/*}*/
/*div.jsoneditor-value.jsoneditor-boolean {*/
/*    color: #ff8048;*/
/*}*/
/*div.jsoneditor-value.jsoneditor-null {*/
/*    color: #49a7fc;*/
/*}*/
/*div.jsoneditor-value.jsoneditor-invalid {*/
/*    color: white;*/
/*}*/
